/*.side-bar {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    border-left: 1px solid;
    border-radius: 0;
    border-color: rgba(64, 194, 133, 0.693);
    background-color: rgb(255, 255, 255);
    transition: 0.8s ease;
    position:absolute;
    top:0px;
}

.toggle-menu {
    height: 50px;
    border-top-left-radius: 10rem;
    border-bottom-left-radius: 9rem;
    width: 10px;
    position: absolute;
    outline: none;
    z-index: 1;
    background-color: rgba(64, 194, 133, 0.693);
    border-color: rgba(64, 194, 133, 0.693);
    border-left: 0;
}*/
/*
.row{
     display: flex;
    flex-direction: column-reverse;
}*/
.content{
    padding-left:20px;
}

#erase{
    position:relative;
    z-index: 100;
}


 .nav button{
  font-size:1em;
      padding: 5px 15px;


    }

    .nav {
    padding: 0px;
    text-align:center;
}

.side button{
      font-size:1em;

     margin:20px;
     padding: 10px 30px;
}


canvas {
    border:1px solid ;
}

 html,
        body {
            width: 100%;
            height: 100%;
            margin: 0;

            /* Prevent document pinch-zoom & touch-hold-to-highlight */
            touch-action: none;

            -webkit-touch-callout: none;
            /* iOS Safari */
            -webkit-user-select: none;
            /* Safari */
            -khtml-user-select: none;
            /* Konqueror HTML */
            -moz-user-select: none;
            /* Old versions of Firefox */
            -ms-user-select: none;
            /* Internet Explorer/Edge */
            user-select: none;
            /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
        }




canvas{position:absolute;}  

/*@media only screen and (min-width: 4068px) {

 button{
    margin:auto;
    padding:10px 15px 10px 15px;
    font-size:18px;
    }

 .side-bar{

    display:none
}

.mobile{display:inline-block;}
}*/
@media only screen and (max-width: 668px) {
  /*.side-bar{
    display:none;
    }*/
    .desktop{display:none}
} 

@media only screen and (min-width: 668px) {
 /* .side-bar{
    display:none;
    }*/


  
 .mobile{display:none}

  

}     
